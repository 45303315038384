<template>
  <div class="container">
    <!-- 头部 -->
    <Header :tab="0" />
    <!-- 身体 -->
    <div class="main">
      <div class="banner wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
        <!-- :height="bannerHeight + 'px'" -->
        <el-carousel height="678px">
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <div ref="carousel_img" class="carousel_imgBox">
              <img :src="item.image" class="banner_img" alt />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>

      <!-- 我们的服务 -->
      <div class="title_box wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
        <div>我们的服务</div>
        <div class="title_box_text">用心服务客户，靠谱有温度的重庆网站建设公司</div>
      </div>
      <div class="accordion wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
        <div class="accordion_item wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
          :data-wow-offset="height_top">
          <div :class="isActive == 1 ? 'accordion_item_box' : 'accordion_item_boxs'
            ">
            <div @mouseover="mouseover(1)" @mouseout="mouseout" class="accordion_item_father" v-if="articleList.length">
              <!-- <img src="../../assets/imgs/business_img1.jpg" class="father_img" alt=""> -->
              <div class="accordion_item_father_title">{{ articleList[0].title }}</div>
              <div class="accordion_item_father_txt">WEBSITE BUILDING</div>
            </div>
            <div class="accordion_item_son" v-if="articleList.length">
              <div class="accordion_item_son_div"></div>
              <div class="accordion_item_son_item" v-for="(item, index) in articleList[0].list" :key="index"
                @click="accordion(item)">
                <div class="accordion_item_son_item_img_box">
                  <img :src="item.image" class="accordion_item_son_item_img" alt />
                </div>
                <div class="accordion_item_son_item_txt">{{ item.title }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion_item wow animate__slideInUp" data-wow-delay="0.4s" data-wow-iteration="1"
          :data-wow-offset="height_top">
          <div :class="isActive == 2 ? 'accordion_item_box' : 'accordion_item_boxs'
            ">
            <div @mouseover="mouseover(2)" @mouseout="mouseout" class="accordion_item_father" v-if="articleList.length">
              <div class="accordion_item_father_title">{{ articleList[1].title }}</div>
              <div class="accordion_item_father_txt">WECHAT MARKETING</div>
            </div>
            <div class="accordion_item_son" v-if="articleList.length">
              <div class="accordion_item_son_div"></div>
              <div class="accordion_item_son_item" v-for="(item, index) in articleList[1].list" :key="index"
                @click="accordion(item)">
                <div class="accordion_item_son_item_img_box">
                  <img :src="item.image" class="accordion_item_son_item_img" alt />
                </div>
                <div class="accordion_item_son_item_txt">{{ item.title }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion_item wow animate__slideInUp" data-wow-delay="0.6s" data-wow-iteration="1"
          :data-wow-offset="height_top">
          <div :class="isActive == 3 ? 'accordion_item_box' : 'accordion_item_boxs'
            ">
            <div @mouseover="mouseover(3)" @mouseout="mouseout" class="accordion_item_father" v-if="articleList.length">
              <div class="accordion_item_father_title">{{ articleList[2].title }}</div>
              <div class="accordion_item_father_txt">MOBILE END</div>
            </div>
            <div class="accordion_item_son" v-if="articleList.length">
              <div class="accordion_item_son_div"></div>
              <div class="accordion_item_son_item" v-for="(item, index) in articleList[2].list" :key="index"
                @click="accordion(item)">
                <div class="accordion_item_son_item_img_box">
                  <img :src="item.image" class="accordion_item_son_item_img" alt />
                </div>
                <div class="accordion_item_son_item_txt">{{ item.title }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion_item wow animate__slideInUp" data-wow-delay="0.8s" data-wow-iteration="1"
          :data-wow-offset="height_top">
          <div :class="isActive == 4 ? 'accordion_item_box' : 'accordion_item_boxs'
            ">
            <div @mouseover="mouseover(4)" @mouseout="mouseout" class="accordion_item_father" v-if="articleList.length">
              <div class="accordion_item_father_title">{{ articleList[3].title }}</div>
              <div class="accordion_item_father_txt">MANAGEMENT SYSTEM</div>
            </div>
            <div class="accordion_item_son" v-if="articleList.length">
              <div class="accordion_item_son_div"></div>
              <div class="accordion_item_son_item" v-for="(item, index) in articleList[3].list" :key="index"
                @click="accordion(item)">
                <div class="accordion_item_son_item_img_box">
                  <img :src="item.image" class="accordion_item_son_item_img" alt />
                </div>
                <div class="accordion_item_son_item_txt">{{ item.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 选择 -->
      <div class="select wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
        <div class="select_left wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
          :data-wow-offset="height_top">
          <img src="../../assets/imgs/chose_0.jpg" class="select_left_img" alt />
        </div>
        <div class="select_right">
          <div class="select_right_title_box">
            <div class="select_right_title">他们都选择了我们</div>
            <div class="select_right_txt">与强者同行，智者为伍，成长共赢！</div>
          </div>

          <div class="select_right_btn wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
            <div class="select_right_btn_box">
              <div v-for="(item, index) in selectList" :key="index" class="select_right_btn_imgbox">
                <img :src="item.image" class="select_right_btn_img" alt />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 案例 -->
      <div class="title_box wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
        <div>我们的成功案例</div>
        <div class="title_box_text">眼光高度决定品牌厚度！</div>
      </div>
      <div class="case wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
        <!-- 上面6个 -->
        <div class="case_top_box">
          <div class="case_top_item">
            <div class="case_top_item_bor wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
              :data-wow-offset="height_top" @click="toDetails(projectsList[0].project_id)">
              <div class="case_top_item_bor_img_box" v-if="projectsList.length">
                <img :src="projectsList[0].image" class="case_top_item_bor_img" alt />
              </div>
              <div class="case_top_item_bor_btn" v-if="projectsList.length">
                <div class="case_top_item_bor_btn_title">{{ projectsList[0].title }}</div>
                <div class="case_top_item_bor_btn_txt">
                  <span v-for="(itm, idx) in  projectsList[0].tags" :key="idx">{{ itm }}</span>
                </div>
              </div>
            </div>
            <div class="case_top_item_bor_1 wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
              :data-wow-offset="height_top" @click="toDetails(projectsList[3].project_id)">
              <div class="case_top_item_bor_img_box" v-if="projectsList.length">
                <img :src="projectsList[3].image" class="case_top_item_bor_img" alt />
              </div>
              <div class="case_top_item_bor_btn" v-if="projectsList.length">
                <div class="case_top_item_bor_btn_title">{{ projectsList[3].title }}</div>
                <div class="case_top_item_bor_btn_txt">
                  <span v-for="(itm, idx) in  projectsList[3].tags" :key="idx">{{ itm }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="case_top_item">
            <div class="case_top_item_bor_1 wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
              :data-wow-offset="height_top" @click="toDetails(projectsList[1].project_id)">
              <div class="case_top_item_bor_img_box" v-if="projectsList.length">
                <img :src="projectsList[1].image" class="case_top_item_bor_img" alt />
              </div>
              <div class="case_top_item_bor_btn" v-if="projectsList.length">
                <div class="case_top_item_bor_btn_title">{{ projectsList[1].title }}</div>
                <div class="case_top_item_bor_btn_txt">
                  <span v-for="(itm, idx) in  projectsList[1].tags" :key="idx">{{ itm }}</span>
                </div>
              </div>
            </div>
            <div class="case_top_item_bor wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
              :data-wow-offset="height_top" @click="toDetails(projectsList[5].project_id)">
              <div class="case_top_item_bor_img_box" v-if="projectsList.length">
                <img :src="projectsList[5].image" class="case_top_item_bor_img" alt />
              </div>
              <div class="case_top_item_bor_btn" v-if="projectsList.length">
                <div class="case_top_item_bor_btn_title">{{ projectsList[5].title }}</div>
                <div class="case_top_item_bor_btn_txt">
                  <span v-for="(itm, idx) in  projectsList[5].tags" :key="idx">{{ itm }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="case_top_item">
            <div class="case_top_item_bor wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
              :data-wow-offset="height_top" @click="toDetails(projectsList[2].project_id)">
              <div class="case_top_item_bor_img_box" v-if="projectsList.length">
                <img :src="projectsList[2].image" class="case_top_item_bor_img" alt />
              </div>
              <div class="case_top_item_bor_btn" v-if="projectsList.length">
                <div class="case_top_item_bor_btn_title">{{ projectsList[2].title }}</div>
                <div class="case_top_item_bor_btn_txt">
                  <span v-for="(itm, idx) in  projectsList[2].tags" :key="idx">{{ itm }}</span>
                </div>
              </div>
            </div>
            <div class="case_top_item_bor wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
              :data-wow-offset="height_top" @click="toDetails(projectsList[4].project_id)">
              <div class="case_top_item_bor_img_box" v-if="projectsList.length">
                <img :src="projectsList[4].image" class="case_top_item_bor_img" alt />
              </div>
              <div class="case_top_item_bor_btn" v-if="projectsList.length">
                <div class="case_top_item_bor_btn_title">{{ projectsList[4].title }}</div>
                <div class="case_top_item_bor_btn_txt">
                  <span v-for="(itm, idx) in  projectsList[4].tags" :key="idx">{{ itm }}</span>
                </div>
              </div>
            </div>
            <div class="case_top_item_bor wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
              :data-wow-offset="height_top" @click="toDetails(projectsList[6].project_id)">
              <div class="case_top_item_bor_img_box" v-if="projectsList.length">
                <img :src="projectsList[6].image" class="case_top_item_bor_img" alt />
              </div>
              <div class="case_top_item_bor_btn" v-if="projectsList.length">
                <div class="case_top_item_bor_btn_title">{{ projectsList[6].title }}</div>
                <div class="case_top_item_bor_btn_txt">
                  <span v-for="(itm, idx) in  projectsList[6].tags" :key="idx">{{ itm }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 下面5个 -->
        <div class="case_btn_box">
          <div class="case_top_item">
            <div class="case_top_item_bor wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
              :data-wow-offset="height_top" @click="toDetails(projectsList[7].project_id)">
              <div class="case_top_item_bor_img_box" v-if="projectsList.length">
                <img :src="projectsList[7].image" class="case_top_item_bor_img" alt />
              </div>
              <div class="case_top_item_bor_btn" v-if="projectsList.length">
                <div class="case_top_item_bor_btn_title">{{ projectsList[7].title }}</div>
                <div class="case_top_item_bor_btn_txt">
                  <span v-for="(itm, idx) in  projectsList[7].tags" :key="idx">{{ itm }}</span>
                </div>
              </div>
            </div>
            <div class="case_top_item_bor wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
              :data-wow-offset="height_top" @click="toDetails(projectsList[9].project_id)">
              <div class="case_top_item_bor_img_box" v-if="projectsList.length">
                <img :src="projectsList[9].image" class="case_top_item_bor_img" alt />
              </div>
              <div class="case_top_item_bor_btn" v-if="projectsList.length">
                <div class="case_top_item_bor_btn_title">{{ projectsList[9].title }}</div>
                <div class="case_top_item_bor_btn_txt">
                  <span v-for="(itm, idx) in  projectsList[9].tags" :key="idx">{{ itm }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="case_top_item">
            <div class="case_top_item_bor wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
              :data-wow-offset="height_top" @click="toDetails(projectsList[8].project_id)">
              <div class="case_top_item_bor_img_box" v-if="projectsList.length">
                <img :src="projectsList[8].image" class="case_top_item_bor_img" alt />
              </div>
              <div class="case_top_item_bor_btn" v-if="projectsList.length">
                <div class="case_top_item_bor_btn_title">{{ projectsList[8].title }}</div>
                <div class="case_top_item_bor_btn_txt">
                  <span v-for="(itm, idx) in  projectsList[8].tags" :key="idx">{{ itm }}</span>
                </div>
              </div>
            </div>
            <div class="case_top_item_bor wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
              :data-wow-offset="height_top" @click="toDetails(projectsList[10].project_id)">
              <div class="case_top_item_bor_img_box" v-if="projectsList.length">
                <img :src="projectsList[10].image" class="case_top_item_bor_img" alt />
              </div>
              <div class="case_top_item_bor_btn" v-if="projectsList.length">
                <div class="case_top_item_bor_btn_title">{{ projectsList[10].title }}</div>
                <div class="case_top_item_bor_btn_txt">
                  <span v-for="(itm, idx) in  projectsList[10].tags" :key="idx">{{ itm }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="case_top_item">
            <div class="case_top_item_bor_1 wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
              :data-wow-offset="height_top" @click="toDetails(projectsList[11].project_id)">
              <div class="case_top_item_bor_img_box" v-if="projectsList.length">
                <img :src="projectsList[11].image" class="case_top_item_bor_img" alt />
              </div>
              <div class="case_top_item_bor_btn" v-if="projectsList.length">
                <div class="case_top_item_bor_btn_title">{{ projectsList[11].title }}</div>
                <div class="case_top_item_bor_btn_txt">
                  <span v-for="(itm, idx) in  projectsList[11].tags" :key="idx">{{ itm }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="case_btn">
          <el-button @click="more">了解更多</el-button>
        </div>
      </div>

      <!-- 我们的观点 -->
      <div class="point wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
        <div class="title_box">
          <div>我们的观点</div>
          <div class="title_box_text">专业团队，品质服务！</div>
        </div>
        <div class="banner">
          <div class="banner_box">
            <div class="title">我们提供专业的解决方案，</div>
            <div class="title">同样我们也注重你的体验！</div>
            <div class="text_box">
              <div>专业来源于十年的技术积累，用心让我们做到更好，</div>
              <div>放心和称心是我们期望给你的体验！</div>
              <div>你感受到的才叫专业！</div>
            </div>
          </div>
        </div>
      </div>

      <!-- 新闻 -->
      <div class="title_box wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
        <div>新闻资讯</div>
        <div class="title_box_text">让中国企业信息化普遍成功</div>
      </div>
      <div class="new wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
        <div class="new_l wow animate__slideInUp" data-wow-delay="0" data-wow-iteration="1" :data-wow-offset="height_top">
          <el-carousel height="459px" indicator-position="outside" arrow="never">
            <el-carousel-item v-for="(item, index) in newsList" :key="index">
              <div class="new_img_box" @click="newDetails(item.id)">
                <img :src="item.image" class="new_img" alt />
              </div>
              <div class="btn" @click="newDetails(item.id)">
                <div class="btn_l">
                  <div class="btn_l_title">{{ item.title }}</div>
                  <div class="btn_l_txt">{{ item.summary }}</div>
                </div>
                <div class="btn_r">
                  <div class="btn_r_title">{{ item.day }}</div>
                  <div class="btn_r_time">{{ item.created_at }}</div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="new_r wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
          :data-wow-offset="height_top">
          <div class="new_r_box">
            <div v-for="(item, index) in hotNewsList" :key="index" class="new_r_item" @click="newDetails(item.id)">
              <div class="new_r_item_l">
                <div class="new_r_item_l_title">{{ item.day }}</div>
                <div class="new_r_item_l_time">{{ item.created_at }}</div>
              </div>
              <div class="new_r_item_r">
                <div class="new_r_item_r_title">{{ item.title }}!</div>
                <div class="new_r_item_r_txt">{{ item.summary }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="case_btns">
        <el-button @click="newMore">了解更多</el-button>
      </div>
    </div>
    <!-- 底部 -->
    <Footer class="wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top" />
  </div>
</template>

<script>
import Footer from "@/components/Footer/Footer";
import Header from "@/components/Header/Header";
import storage from "@/utils/storage";
import {
  adlist,
  articlelist4,
  articlelist9,
  articlelist10,
  articlelist10recommend,
  articleindexrecommends
} from "@/api/axios/axios.api";
import { WOW } from "wowjs";
console.log('height_top', storage.height_top);
export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      bannerHeight: '',
      isShow: false,
      height_top: storage.height_top,
      bannerList: [],
      articleList: [],
      selectList: [],
      newsList: [],
      hotNewsList: [],
      projectsList: [],
      timer: null,
      isActive: 1,
      current: 1 //当前显示页
    };
  },
  methods: {
    // 手风琴
    mouseover(e) {
      this.isActive = e;
    },
    mouseout() { },
    // 服务
    accordion(item) {
      // this.$router.push(item.url);
      window.location.href = item.url
    },
    // 案例详情
    toDetails(id) {
      this.$router.push({
        name: "sucsessdetails",
        query: {
          id
        }
      });
    },
    // 案例更多
    more() {
      this.$router.push({
        name: "sucsess",
      });
    },
    // 咨询详情
    newDetails(id) {
      this.$router.push({
        name: "newsdetails",
        query: {
          id: id
        }
      });
    },
    // 咨询更多
    newMore() {
      this.$router.push({
        name: "news"
      });
    },

    // 获取数据
    async getAdlist() {
      // 获取首页轮播图
      let res = await adlist({ pid: 1 });
      this.bannerList = res.data.list;

      setTimeout(() => {
        if (this.$refs.carousel_img) {
          this.getbannerHeight()
        }
        // let wow = new WOW({
        //   live: true
        // });
        // wow.init();
      }, 0);


      // 获取首页-我们的服务/底部导航/案例类型
      let rec = await articlelist4();
      this.articleList = rec.data.list;
      // 获取首页-合作伙伴
      let ret = await articlelist9();
      this.selectList = ret.data.list;
      // this.getNews()
    },
    async getNews() {
      // 获取成功的案例
      let rec = await articleindexrecommends();
      // this.projectsList = rec.data.list.map(item =>{return item})
      let list = rec.data.list;
      for (const key in list) {
        if (Object.hasOwnProperty.call(list, key)) {
          this.projectsList.push(list[key]);
        }
      }
      this.isShow = true


      // 获取新闻推荐列表
      let res = await articlelist10recommend({ page: this.current });
      this.newsList = res.data.list;
      // 获取新闻列表
      let ret = await articlelist10({ page: this.current });
      ret.data.list.forEach((item, index) => {
        if (index < 6) {
          this.hotNewsList.push(item);
        }
      });
    },

    // 缩放比例
    resize() {
      // 系统整体缩放
      let cliWidth =
        document.documentElement.clientWidth || document.body.clientWidth;
      let cliHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      let contW = 1920;
      let contH = 1080;
      let w = cliWidth / contW;
      let h = cliHeight / contH;
      let appDom = document.querySelector(".container");
      appDom.style.transform = "scale(" + w + "," + h + ")";
      appDom.style.transformOrigin = "top left";
      appDom.style.width = contW + "px";
      appDom.style.height = contH + "px";
    },
    // 轮播图高度
    getbannerHeight() {
      this.timer = setInterval(() => {
        if (this.$refs.carousel_img) {
          clearInterval(this.timer)
          this.timer = null
          this.bannerHeight = document.querySelector(".carousel_imgBox").getBoundingClientRect().height
          if (window.outerHeight === screen.availHeight && window.outerWidth === screen.availWidth) {
            this.bannerHeight = document.querySelector(".carousel_imgBox").getBoundingClientRect().height
          } else {
            this.bannerHeight = document.querySelector(".carousel_imgBox").getBoundingClientRect().height
          }
        } else {
          clearInterval(this.timer)
          this.timer = null
          this.getbannerHeight()
        }
      }, 200);

    },
  },
  created() { },
  mounted() {
    this.getAdlist();
    this.getNews();
    this.$nextTick(() => {
      // this.resize();
      // window.onresize = function() {
      // this.resize();
      // }.bind(this);

      let wow = new WOW({
        live: true
      });
      wow.init();
      this.getbannerHeight()

    });
  },
  watch: {
    bannerHeight(e) {
      this.getbannerHeight()
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="less" scoped>
// .container {
//     /* 
//         vh: 基于视窗比例设置高度
//         vw: 基于视窗比例设置宽度

//         两者都是讲视窗尺寸均分为100份
//     */
//     min-height: 100%;
//     display: flex;
//     display: -moz-box; /* Firefox 17- */  
//     display: -webkit-flex; /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */  
//     display: -moz-flex; /* Firefox 18+ */  
//     display: -ms-flexbox; /* IE 10 */ 
//     -webkit-box-orient: vertical;
//     flex-direction: column;
//     -webkit-flex-direction: column;
//     -ms-flex-direction: column;
//     -moz-flex-direction: column;
//     -o-flex-direction: column;
//     justify-content: space-between;
//     background: #F5F5F5;
//     .main{
//         -webkit-flex: 1; /* Chrome */
//         -ms-flex: 1; /* IE 10 */
//         flex: 1; /* NEW, Spec - Opera 12.1, Firefox 20+ */
//         -webkit-box-flex: 1; /* OLD - iOS 6-, Safari 3.1-6 */
//         -moz-box-flex: 1; /* OLD - Firefox 19- */
//     }
// }

@media screen and (min-width : 1400px) {
  .title_box {

    padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #333333;
    font-size: 28px;

    .title_box_text {
      margin-top: 21px;
      font-weight: normal;
      color: #666666;
      font-size: 18px;
    }
  }

  .banner {
    height: 678px;
    min-width: 1300px;
    position: relative;

    .banner_img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 678px;
      // min-width: 1300px;
      // height: 100%;
    }
  }

  // 手风琴
  .accordion {
    padding: 0 10% 100px 10%;
    display: flex;

    .accordion_item {
      height: 500px;
      overflow: hidden;
      box-sizing: border-box;

      .accordion_item_box {
        display: flex;

        .accordion_item_father {
          min-width: 290px;
          // height: 500px;
          // background: url(../../assets/imgs/business_img1.jpg) no-repeat;
          // background-size: 100% 100%;
          // transition: all 0.5s;

          .accordion_item_father_title {
            padding-top: 78px;
            padding-bottom: 18px;
            color: #fff;
            font-size: 24px;
            text-align: center;
          }

          .accordion_item_father_txt {
            text-align: center;
            color: #fff;
            font-size: 16px;
          }
        }

        .accordion_item_son {
          position: relative;
          width: 417px;
          height: 500px;
          background-color: #fff;
          display: flex;
          flex-wrap: wrap;
          // padding: 40px 0;
          box-sizing: border-box;
          opacity: 1;
          transition: all 5s;
          overflow: hidden;

          .accordion_item_son_div {
            width: 20px;
            height: 20px;
            background-color: #fff;
            position: absolute;
            top: calc(50% - 10px);
            left: -10px;
            z-index: 100;
            transform: rotate(45deg);
            opacity: 1;
            transition: all 1s;
          }

          .accordion_item_son_item {
            // width: calc(100% - 180px) / 3;
            width: calc(100% / 3);
            // max-height: 167px;
            max-height: 250px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px 5px;
            color: #666666;
            font-size: 14px;
            text-align: center;
            box-sizing: border-box;
            opacity: 1;
            transition: all 0.5s;

            .accordion_item_son_item_img_box {
              width: 79px;
              height: 79px;
              background: #eff3f5;
              border-radius: 50%;
              margin-bottom: 23px;
              opacity: 1;
              transition: all 1s;

              .accordion_item_son_item_img {
                width: 79px;
                height: 79px;
                background: #eff3f5;
                border-radius: 50%;
                // margin-bottom: 23px;
                transition: all 0.5s;
                opacity: 1;
              }
            }

            .accordion_item_son_item_txt {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              transition: all 1s;
              opacity: 1;
            }
          }

          .accordion_item_son_item:hover {
            // box-shadow: -10px -10px 10px 10px #ccc inset;
            transition: all 0.5s;
            // border-radius: 50%;
            box-shadow: 0 0 20px rgba(153, 153, 153, 1);
            z-index: 1000;
            cursor: pointer;
          }

          .accordion_item_son_item:hover .accordion_item_son_item_img {
            transform: scale(1.2);
            transition: all 0.5s;
          }
        }

        .accordion_item_father:hover {
          transition: all 0.5s;
        }

        // .accordion_item_father:hover~.accordion_item_son {
        //   width: 417px;
        //   transition: all 0.5s;
        // }
      }

      .accordion_item_box:hover .accordion_item_son {
        // display: block;
        width: 417px;
        transition: all 1s;
      }


      .accordion_item_boxs {
        .accordion_item_father {
          width: 290px;
          height: 500px;
          // background: url(../../assets/imgs/head-pic.png) no-repeat;
          // background-size: 100% 100%;
          // transition: all 0.5s;

          .accordion_item_father_title {
            padding-top: 78px;
            padding-bottom: 18px;
            color: #666666;
            font-size: 24px;
            text-align: center;
          }

          .accordion_item_father_txt {
            text-align: center;
            color: #999999;
            font-size: 16px;
          }
        }

        .accordion_item_son {
          position: relative;
          width: 0;
          height: 500px;
          background-color: #fff;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          padding: 40px 0;
          box-sizing: border-box;
          overflow: hidden;
          opacity: 0;
          // display: none;
          transition: all 5s;
          overflow: hidden;


          .accordion_item_son_div {
            width: 20px;
            height: 20px;
            background-color: #fff;
            position: absolute;
            top: calc(50% - 10px);
            left: -10px;
            z-index: 100;
            transform: rotate(45deg);
            opacity: 0;
            transition: all 1s;
          }

          .accordion_item_son_item {
            // width: calc(100% - 180px) / 3;
            width: calc(100% / 3);
            padding: 20px;
            color: #666666;
            font-size: 14px;
            // max-height: 167px;
            max-height: 250px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px 5px;
            box-sizing: border-box;
            opacity: 0;
            transition: all 0.5s;

            .accordion_item_son_item_img_box {
              width: 79px;
              height: 79px;
              background: #eff3f5;
              border-radius: 50%;
              margin-bottom: 23px;
              opacity: 1;
              transition: all 1s;

              .accordion_item_son_item_img {
                width: 79px;
                height: 79px;
                background: #eff3f5;
                border-radius: 50%;
                // margin-bottom: 23px;
                opacity: 1;
              }
            }

            .accordion_item_son_item_txt {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              opacity: 0;
            }
          }
        }

        .accordion_item_son_item:hover .accordion_item_son_item_img {
          transform: scale(1.2);
          transition: all 0.5s;
        }

        .accordion_item_father:hover {
          transition: all 0.5s;
        }

        // .accordion_item_father:hover~.accordion_item_son {
        //   width: 417px;
        //   transition: all 0.5s;
        // }

      }

      .accordion_item_boxs:hover .accordion_item_son {
        display: block;
        width: 417px;
        transition: all 1s;
      }
    }

    .father_img {
      width: 100%;
    }


    .accordion_item:nth-child(1) .accordion_item_box .accordion_item_father {
      background: url(../../assets/imgs/business_img1.jpg) no-repeat;
      // background-size: 100% 100%;
    }

    .accordion_item:nth-child(2) .accordion_item_box .accordion_item_father {
      background: url(../../assets/imgs/business_img5.jpg) no-repeat;
      // background-size: 100% 100%;
    }

    .accordion_item:nth-child(3) .accordion_item_box .accordion_item_father {
      background: url(../../assets/imgs/business_img67.jpg) no-repeat;
      // background-size: 100% 100%;
    }

    .accordion_item:nth-child(4) .accordion_item_box .accordion_item_father {
      background: url(../../assets/imgs/baohe2.png) no-repeat;
      // background-size: 100% 100%;
    }



    .accordion_item:nth-child(1) .accordion_item_boxs .accordion_item_father {
      background: url(../../assets/imgs/business_img4.jpg) no-repeat;
      // background-size: 100% 100%;
    }

    .accordion_item:nth-child(2) .accordion_item_boxs .accordion_item_father {
      background: url(../../assets/imgs/business_img2.jpg) no-repeat;
      // background-size: 100% 100%;
    }

    .accordion_item:nth-child(3) .accordion_item_boxs .accordion_item_father {
      background: url(../../assets/imgs/business_img31.jpg) no-repeat;
      // background-size: 100% 100%;
    }

    .accordion_item:nth-child(4) .accordion_item_boxs .accordion_item_father {
      background: url(../../assets/imgs/baohe.png) no-repeat;
      // background-size: 100% 100%;
    }


  }

  // 选择
  .select {
    min-width: 1300px;
    display: flex;
    // height: 889px;
    // overflow: hidden;
    position: relative;

    .select_left {
      width: 721px;
      // width: 30%;
      // height: 100%;

      .select_left_img {
        width: 721px;
        height: 100%;
      }
    }

    .select_right {
      flex: 1;
      background-color: #fff;
      display: flex;
      flex-direction: column;

      .select_right_title_box {
        text-align: center;
        // padding: 141px 0 100px 0;
        height: 338px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;

        .select_right_title {
          color: #333333;
          font-size: 42px;
          margin-bottom: 36px;
        }

        .select_right_txt {
          color: #666666;
          font-size: 18px;
        }
      }

      .select_right_btn {
        flex: 1;
        // max-width: 1200px;
        width: 100%;
        height: calc(100% - 338px);
        box-sizing: border-box;
        // position: absolute;
        position: relative;
        right: 10%;
        bottom: 0;
        background-color: #fff;

        .select_right_btn_box {
          display: flex;
          flex-wrap: wrap;

          .select_right_btn_imgbox {
            width: calc(100% / 6);
            // width: calc(100% / 6);
            background-color: #fff;
            // height: 111px;
            overflow: hidden;
            box-sizing: border-box;
          }

          .select_right_btn_img {
            width: 100%;
            height: 100%;
            border: 1px solid #e5e5e5;
            // border-top: 1px solid #e5e5e5;
            // border-right: 1px solid #e5e5e5;
            transition: all 0.5s;
            margin: 0 -1px -1px 0;
            box-sizing: border-box;
          }

          .select_right_btn_imgbox:hover {
            transition: all 0.5s;
            box-shadow: 0 0 20px rgba(153, 153, 153, 1);
            position: relative;
            z-index: 1000;
          }

          .select_right_btn_imgbox:hover .select_right_btn_img {
            transform: scale(1.2);
            transition: all 0.5s;
          }
        }
      }
    }
  }

  // 案例
  .case {
    margin: 0 10%;
    background-color: #fff;
    padding: 5% 10% 2% 10%;
    margin-bottom: 5%;
    box-sizing: border-box;
    box-shadow: 0px 9px 11px 3px rgba(153, 153, 153, 0.07);

    .case_top_box {
      display: flex;
      align-content: space-around;

      .case_top_item {
        width: calc(100% / 3);
        box-sizing: border-box;

        .case_top_item_bor {
          height: calc(100% / 3);
          width: 100%;
          display: flex;
          flex-direction: column;
          transition: all 0.5s;
          position: relative;

          .case_top_item_bor_img_box {
            flex: 1;
            // width: 380px;
            // height: 234px;
            max-width: calc(100% - 50px) / 3;
            max-height: 234px;
            overflow: hidden;
          }

          .case_top_item_bor_img {
            // width: 380px;
            // height: 234px;
            // max-width: calc(100% - 50px) / 3;
            // max-height: 234px;
            width: 100%;
            height: 100%;
            transition: all 0.5s;
          }

          .case_top_item_bor_btn {
            padding: 21px 0;
            color: #333333;
            font-size: 16px;

            .case_top_item_bor_btn_title {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              margin-bottom: 9px;
            }

            .case_top_item_bor_btn_txt {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              color: #999999;
              font-size: 14px;

              span {
                margin-right: 10px;
              }
            }
          }
        }

        .case_top_item_bor:hover {
          transition: all 0.5s;
          // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
          z-index: 10000;
        }

        .case_top_item_bor:hover .case_top_item_bor_img {
          transform: scale(1.2);
        }

        .case_top_item_bor_1 {
          height: calc(100% / 3 * 2);
          width: 100%;
          // overflow: hidden;
          display: flex;
          flex-direction: column;
          position: relative;

          .case_top_item_bor_img_box {
            // width: 380px;
            // height: 557px;
            flex: 1;
            max-width: calc(100% - 50px) / 3;
            max-height: 557px;
            overflow: hidden;
          }

          .case_top_item_bor_img {
            // width: 380px;
            // height: 557px;
            // max-width: calc(100% - 50px) / 3;
            // max-height: 557px;
            width: 100%;
            height: 100%;
            transition: all 0.5s;
          }

          .case_top_item_bor_btn {
            padding: 21px 0;
            color: #333333;
            font-size: 16px;

            .case_top_item_bor_btn_title {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              margin-bottom: 9px;
            }

            .case_top_item_bor_btn_txt {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              color: #999999;
              font-size: 14px;

              span {
                margin-right: 10px;
              }
            }
          }
        }

        .case_top_item_bor_1:hover {
          transition: all 0.5s;
          // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
          z-index: 100;
        }

        .case_top_item_bor_1:hover .case_top_item_bor_img {
          transform: scale(1.2);
        }
      }

      .case_top_item:nth-child(2) {
        margin: 0 25px;
      }
    }

    .case_btn_box {
      display: flex;
      align-content: space-around;

      .case_top_item {
        width: calc(100% / 3);
        box-sizing: border-box;

        .case_top_item_bor {
          height: calc(100% / 2);
          width: 100%;
          display: flex;
          flex-direction: column;
          position: relative;

          .case_top_item_bor_img_box {
            flex: 1;
            // width: 380px;
            // height: 234px;
            max-width: calc(100% - 50px) / 3;
            max-height: 234px;
            overflow: hidden;
          }

          .case_top_item_bor_img {
            // width: 380px;
            // height: 234px;
            // max-width: calc(100% - 50px) / 3;
            // max-height: 234px;
            width: 100%;
            height: 100%;
            transition: all 0.5s;
          }

          .case_top_item_bor_img:hover {
            transform: scale(1.2);
          }

          .case_top_item_bor_btn {
            padding: 21px 0;
            color: #333333;
            font-size: 16px;

            .case_top_item_bor_btn_title {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              margin-bottom: 9px;
            }

            .case_top_item_bor_btn_txt {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              color: #999999;
              font-size: 14px;

              span {
                margin-right: 10px;
              }
            }
          }
        }

        .case_top_item_bor:hover {
          transition: all 0.5s;
          // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
          z-index: 100;
        }

        .case_top_item_bor:hover .case_top_item_bor_img {
          transform: scale(1.2);
        }

        .case_top_item_bor_1 {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          position: relative;

          .case_top_item_bor_img_box {
            flex: 1;
            // width: 380px;
            // height: 557px;
            max-width: calc(100% - 50px) / 3;
            max-height: 557px;
            overflow: hidden;
          }

          .case_top_item_bor_img {
            // width: 380px;
            // height: 557px;
            // max-width: calc(100% - 50px) / 3;
            // max-height: 557px;
            width: 100%;
            height: 100%;
            transition: all 0.5s;
          }

          .case_top_item_bor_img:hover {
            transform: scale(1.2);
          }

          .case_top_item_bor_btn {
            padding: 21px 0;
            color: #333333;
            font-size: 16px;

            .case_top_item_bor_btn_title {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              margin-bottom: 9px;
            }

            .case_top_item_bor_btn_txt {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              color: #999999;
              font-size: 14px;

              span {
                margin-right: 10px;
              }
            }
          }
        }

        .case_top_item_bor_1:hover {
          transition: all 0.5s;
          // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
          z-index: 100;
        }

        .case_top_item_bor_1:hover .case_top_item_bor_img {
          transform: scale(1.2);
        }
      }

      .case_top_item:nth-child(2) {
        margin: 0 25px;
      }
    }

    .case_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 20px;

      .el-button {
        width: 380px;
        height: 60px;
        background: #000000;
        padding: 0;
        margin: 0;
        overflow: hidden;
        font-size: 16px;
        color: #ffffff;
      }
    }
  }

  // 观点
  .point {
    background-color: #fff;
    color: #fff;

    .banner {
      background: url("../../assets/imgs/juxing.png") no-repeat;
      background-size: 100% 100%;
      height: 660px;
      padding: 156px 10%;
      box-sizing: border-box;

      .title {
        font-size: 48px;
        line-height: 76px;
      }

      .text_box {
        margin-top: 92px;
        line-height: 34px;
      }
    }
  }

  // 新闻
  .new {
    display: flex;
    align-content: space-around;
    padding: 0 10%;

    .new_l {
      width: 616px;
      // max-height: 559px;
      margin-right: 25px;
      padding: 50px 69px;
      box-sizing: border-box;
      background-color: #fff;
      cursor: pointer;

      .new_img_box {
        width: 478px;
        height: 313px;
        overflow: hidden;

        .new_img {
          width: 478px;
          height: 313px;
          transition: all 0.5s;
        }

        .new_img:hover {
          transition: all 0.5s;
          transform: scale(1.2);
        }
      }

      .btn {
        display: flex;
        align-content: space-around;
        align-items: center;

        .btn_l {
          flex: 1;
          margin-right: 19px;

          .btn_l_title {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            color: #333333;
            font-size: 18px;
            margin: 20px 0;
          }

          .btn_l_txt {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            color: #999999;
            font-size: 14px;
            line-height: 26px;
          }
        }

        .btn_r {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #fff;
          width: 88px;
          height: 88px;
          background: #000000;
          border-radius: 10px;
          margin-top: 20px;
          font-size: 14px;

          .btn_r_title {
            font-size: 38px;
          }
        }
      }
    }

    .new_r {
      flex: 1;
      background: #fff;

      .new_r_box {
        height: 100%;
        display: flex;
        align-content: space-around;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        // overflow: hidden;

        .new_r_item {
          width: calc(100% / 2);
          // height: calc(100% / 3);
          height: calc(100% / 3);
          display: flex;
          align-content: space-around;
          align-items: center;
          justify-content: center;
          color: #333333;
          border-bottom: 1px solid #e5e5e5;
          border-right: 1px solid #e5e5e5;
          padding: 40px 30px;
          box-sizing: border-box;
          position: relative;
          right: -1px;
          bottom: -1px;
          cursor: pointer;
          transition: all 0.5s;

          .new_r_item_l {
            width: 88px;
            height: 88px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .new_r_item_l_title {
              font-size: 28px;
            }

            .new_r_item_l_time {
              font-size: 16px;
            }
          }

          .new_r_item_r {
            flex: 1;

            .new_r_item_r_title {
              font-size: 18px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              line-height: 34px;
            }

            .new_r_item_r_txt {
              font-size: 14px;
              color: #999999;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              line-height: 20px;
            }
          }
        }

        .new_r_item:nth-child(2) {
          border-right: 0;
        }

        .new_r_item:nth-child(4) {
          border-right: 0;
        }

        .new_r_item:nth-child(5) {
          border-bottom: 0;
        }

        .new_r_item:nth-child(6) {
          border-right: 0;
          border-bottom: 0;
        }

        .new_r_item:hover {
          transition: all 0.5s;
          box-shadow: 0 0 20px rgba(153, 153, 153, 1);
        }
      }
    }
  }

  .case_btns {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 48px 0;

    .el-button {
      width: 380px;
      height: 60px;
      background: #000000;
      padding: 0;
      margin: 0;
      overflow: hidden;
      font-size: 16px;
      color: #ffffff;
    }
  }

  /deep/ .el-carousel__indicators {
    text-align: left;
  }

  /deep/ .is-active .el-carousel__button {
    width: 9px;
    height: 9px;
    background: #000000 !important;
    border-radius: 50%;
  }

  /deep/ .el-carousel__indicator .el-carousel__button {
    width: 9px;
    height: 9px;
    background: #d2d2d2;
    border-radius: 50%;
  }

  /deep/.new .el-carousel__indicator--horizontal {
    padding: 0 4px;
  }
}

@media screen and (max-width : 1399px) {
  .title_box {
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #333333;
    font-size: 28px;

    .title_box_text {
      margin-top: 21px;
      font-weight: normal;
      color: #666666;
      font-size: 18px;
    }
  }

  .banner {
    min-width: 1300px;
    height: 678px;

    .banner_img {
      width: 100%;
      height: 678px;
    }
  }

  // 手风琴
  .accordion {
    padding: 0 20px 100px 20px;
    display: flex;
    min-width: 1300px;

    .accordion_item {
      height: 400px;
      overflow: hidden;
      box-sizing: border-box;

      .accordion_item_box {
        display: flex;

        .accordion_item_father {
          // min-width: calc(100% / 5);
          width: 225px;
          height: 400px;
          // background: url(../../assets/imgs/business_img1.jpg) no-repeat;
          // background-size: 100% 100%;
          transition: all 0.5s;

          .accordion_item_father_title {
            padding-top: 78px;
            padding-bottom: 18px;
            color: #fff;
            font-size: 24px;
            text-align: center;
          }

          .accordion_item_father_txt {
            text-align: center;
            color: #fff;
            font-size: 16px;
          }
        }

        .accordion_item_son {
          position: relative;
          width: 417px;
          height: 400px;
          background-color: #fff;
          display: flex;
          flex-wrap: wrap;
          // padding: 40px 0;
          box-sizing: border-box;
          opacity: 1;
          transition: all 3s;

          .accordion_item_son_div {
            width: 20px;
            height: 20px;
            background-color: #fff;
            position: absolute;
            top: calc(50% - 10px);
            left: -10px;
            z-index: 100;
            transform: rotate(45deg);
            opacity: 1;
            transition: all 1s;
          }

          .accordion_item_son_item {
            // width: calc(100% - 180px) / 3;
            width: calc(100% / 3);
            // max-height: 167px;
            max-height: 250px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px 5px;
            color: #666666;
            font-size: 14px;
            text-align: center;
            box-sizing: border-box;
            opacity: 1;
            transition: all 0.5s;

            .accordion_item_son_item_img_box {
              width: 79px;
              height: 79px;
              background: #eff3f5;
              border-radius: 50%;
              margin-bottom: 23px;
              opacity: 1;
              transition: all 1s;

              .accordion_item_son_item_img {
                width: 79px;
                height: 79px;
                background: #eff3f5;
                border-radius: 50%;
                // margin-bottom: 23px;
                transition: all 0.5s;
                opacity: 1;
              }
            }

            .accordion_item_son_item_txt {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              transition: all 1s;
              opacity: 1;
            }
          }

          .accordion_item_son_item:hover {
            // box-shadow: -10px -10px 10px 10px #ccc inset;
            transition: all 0.5;
            // border-radius: 50%;
            box-shadow: 0 0 20px rgba(153, 153, 153, 1);
            z-index: 1000;
            cursor: pointer;
          }

          .accordion_item_son_item:hover .accordion_item_son_item_img {
            transform: scale(1.2);
            transition: all 0.5s;
          }
        }

        .accordion_item_father:hover {
          transition: all 0.5s;
        }

        .accordion_item_father:hover~.accordion_item_son {
          width: 417px;
          transition: all 1s;
        }
      }


      .accordion_item_boxs {
        .accordion_item_father {
          // width: 290px;
          width: 225px;
          height: 400px;
          // background: url(../../assets/imgs/head-pic.png) no-repeat;
          // background-size: 100% 100%;
          transition: all 0.5s;

          .accordion_item_father_title {
            padding-top: 78px;
            padding-bottom: 18px;
            color: #666666;
            font-size: 24px;
            text-align: center;
          }

          .accordion_item_father_txt {
            text-align: center;
            color: #999999;
            font-size: 16px;
          }
        }

        .accordion_item_son {
          position: relative;
          width: 0;
          height: 400px;
          background-color: #fff;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          padding: 40px 0;
          box-sizing: border-box;
          overflow: hidden;
          opacity: 0;
          transition: all 3s;

          .accordion_item_son_div {
            width: 20px;
            height: 20px;
            background-color: #fff;
            position: absolute;
            top: calc(50% - 10px);
            left: -10px;
            z-index: 100;
            transform: rotate(45deg);
            opacity: 0;
            transition: all 1s;
          }

          .accordion_item_son_item {
            // width: calc(100% - 180px) / 3;
            width: calc(100% / 3);
            padding: 20px;
            color: #666666;
            font-size: 14px;
            // max-height: 167px;
            max-height: 250px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px 5px;
            box-sizing: border-box;
            opacity: 0;
            transition: all 0.5s;

            .accordion_item_son_item_img_box {
              width: 79px;
              height: 79px;
              background: #eff3f5;
              border-radius: 50%;
              margin-bottom: 23px;
              opacity: 1;
              transition: all 1s;

              .accordion_item_son_item_img {
                width: 79px;
                height: 79px;
                background: #eff3f5;
                border-radius: 50%;
                // margin-bottom: 23px;
                opacity: 1;
              }
            }

            .accordion_item_son_item_txt {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              opacity: 0;
            }
          }
        }

        .accordion_item_son_item:hover .accordion_item_son_item_img {
          transform: scale(1.2);
          transition: all 0.5s;
        }

        .accordion_item_father:hover {
          transition: all 0.5s;
        }

        .accordion_item_father:hover~.accordion_item_son {
          width: 417px;
          transition: all 1s;
        }

      }
    }

    .accordion_item:nth-child(1) .accordion_item_box .accordion_item_father {
      background: url(../../assets/imgs/business_img1.jpg) no-repeat;
      background-size: 100% 100%;
    }

    .accordion_item:nth-child(2) .accordion_item_box .accordion_item_father {
      background: url(../../assets/imgs/business_img5.jpg) no-repeat;
      background-size: 100% 100%;
    }

    .accordion_item:nth-child(3) .accordion_item_box .accordion_item_father {
      background: url(../../assets/imgs/business_img67.jpg) no-repeat;
      background-size: 100% 100%;
    }

    .accordion_item:nth-child(4) .accordion_item_box .accordion_item_father {
      background: url(../../assets/imgs/baohe2.png) no-repeat;
      background-size: 100% 100%;
    }



    .accordion_item:nth-child(1) .accordion_item_boxs .accordion_item_father {
      background: url(../../assets/imgs/business_img4.jpg) no-repeat;
      background-size: 100% 100%;
    }

    .accordion_item:nth-child(2) .accordion_item_boxs .accordion_item_father {
      background: url(../../assets/imgs/business_img2.jpg) no-repeat;
      background-size: 100% 100%;
    }

    .accordion_item:nth-child(3) .accordion_item_boxs .accordion_item_father {
      background: url(../../assets/imgs/business_img31.jpg) no-repeat;
      background-size: 100% 100%;
    }

    .accordion_item:nth-child(4) .accordion_item_boxs .accordion_item_father {
      background: url(../../assets/imgs/baohe.png) no-repeat;
      background-size: 100% 100%;
    }


  }

  // 选择
  .select {
    min-width: 1300px;
    display: flex;
    // height: 889px;
    // overflow: hidden;
    position: relative;

    .select_left {
      width: 30%;
      // height: 100%;

      .select_left_img {
        width: 100%;
        height: 100%;
      }
    }

    .select_right {
      flex: 1;
      background-color: #fff;
      display: flex;
      flex-direction: column;

      .select_right_title_box {
        text-align: center;
        // padding: 141px 0 100px 0;
        height: 338px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;

        .select_right_title {
          color: #333333;
          font-size: 42px;
          margin-bottom: 36px;
        }

        .select_right_txt {
          color: #666666;
          font-size: 18px;
        }
      }

      .select_right_btn {
        flex: 1;
        // max-width: 1200px;
        width: 100%;
        height: calc(100% - 338px);
        box-sizing: border-box;
        // position: absolute;
        position: relative;
        right: 0;
        bottom: 0;
        background-color: #fff;

        .select_right_btn_box {
          display: flex;
          flex-wrap: wrap;

          .select_right_btn_imgbox {
            width: calc(100% / 6);
            // width: calc(100% / 6);
            background-color: #fff;
            // height: 111px;
            overflow: hidden;
            box-sizing: border-box;
          }

          .select_right_btn_img {
            width: 100%;
            height: 100%;
            border: 1px solid #e5e5e5;
            // border-top: 1px solid #e5e5e5;
            // border-right: 1px solid #e5e5e5;
            transition: all 0.5s;
            margin: 0 -1px -1px 0;
            box-sizing: border-box;
          }

          .select_right_btn_imgbox:hover {
            transition: all 0.5s;
            box-shadow: 0 0 20px rgba(153, 153, 153, 1);
            position: relative;
            z-index: 1000;
          }

          .select_right_btn_imgbox:hover .select_right_btn_img {
            transform: scale(1.2);
            transition: all 0.5s;
          }
        }
      }
    }
  }

  // 案例
  .case {
    min-width: 1300px;
    margin: 0;
    background-color: #fff;
    padding: 5% 20px 2% 20px;
    margin-bottom: 5%;
    box-sizing: border-box;
    box-shadow: 0px 9px 11px 3px rgba(153, 153, 153, 0.07);

    .case_top_box {
      display: flex;
      align-content: space-around;

      .case_top_item {
        width: calc(100% / 3);
        box-sizing: border-box;

        .case_top_item_bor {
          height: calc(100% / 3);
          width: 100%;
          display: flex;
          flex-direction: column;
          transition: all 0.5s;
          position: relative;

          .case_top_item_bor_img_box {
            flex: 1;
            // width: 380px;
            // height: 234px;
            max-width: calc(100% - 50px) / 3;
            max-height: 234px;
            overflow: hidden;
          }

          .case_top_item_bor_img {
            // width: 380px;
            // height: 234px;
            // max-width: calc(100% - 50px) / 3;
            // max-height: 234px;
            width: 100%;
            height: 100%;
            transition: all 0.5s;
          }

          .case_top_item_bor_btn {
            padding: 21px 0;
            color: #333333;
            font-size: 16px;

            .case_top_item_bor_btn_title {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              margin-bottom: 9px;
            }

            .case_top_item_bor_btn_txt {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              color: #999999;
              font-size: 14px;

              span {
                margin-right: 10px;
              }
            }
          }
        }

        .case_top_item_bor:hover {
          transition: all 0.5s;
          // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
          z-index: 10000;
        }

        .case_top_item_bor:hover .case_top_item_bor_img {
          transform: scale(1.2);
        }

        .case_top_item_bor_1 {
          height: calc(100% / 3 * 2);
          width: 100%;
          // overflow: hidden;
          display: flex;
          flex-direction: column;
          position: relative;

          .case_top_item_bor_img_box {
            // width: 380px;
            // height: 557px;
            flex: 1;
            max-width: calc(100% - 50px) / 3;
            max-height: 557px;
            overflow: hidden;
          }

          .case_top_item_bor_img {
            // width: 380px;
            // height: 557px;
            // max-width: calc(100% - 50px) / 3;
            // max-height: 557px;
            width: 100%;
            height: 100%;
            transition: all 0.5s;
          }

          .case_top_item_bor_btn {
            padding: 21px 0;
            color: #333333;
            font-size: 16px;

            .case_top_item_bor_btn_title {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              margin-bottom: 9px;
            }

            .case_top_item_bor_btn_txt {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              color: #999999;
              font-size: 14px;

              span {
                margin-right: 10px;
              }
            }
          }
        }

        .case_top_item_bor_1:hover {
          transition: all 0.5s;
          // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
          z-index: 100;
        }

        .case_top_item_bor_1:hover .case_top_item_bor_img {
          transform: scale(1.2);
        }
      }

      .case_top_item:nth-child(2) {
        margin: 0 25px;
      }
    }

    .case_btn_box {
      display: flex;
      align-content: space-around;

      .case_top_item {
        width: calc(100% / 3);
        box-sizing: border-box;

        .case_top_item_bor {
          height: calc(100% / 2);
          width: 100%;
          display: flex;
          flex-direction: column;
          position: relative;

          .case_top_item_bor_img_box {
            flex: 1;
            // width: 380px;
            // height: 234px;
            max-width: calc(100% - 50px) / 3;
            max-height: 234px;
            overflow: hidden;
          }

          .case_top_item_bor_img {
            // width: 380px;
            // height: 234px;
            // max-width: calc(100% - 50px) / 3;
            // max-height: 234px;
            width: 100%;
            height: 100%;
            transition: all 0.5s;
          }

          .case_top_item_bor_img:hover {
            transform: scale(1.2);
          }

          .case_top_item_bor_btn {
            padding: 21px 0;
            color: #333333;
            font-size: 16px;

            .case_top_item_bor_btn_title {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              margin-bottom: 9px;
            }

            .case_top_item_bor_btn_txt {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              color: #999999;
              font-size: 14px;

              span {
                margin-right: 10px;
              }
            }
          }
        }

        .case_top_item_bor:hover {
          transition: all 0.5s;
          // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
          z-index: 100;
        }

        .case_top_item_bor:hover .case_top_item_bor_img {
          transform: scale(1.2);
        }

        .case_top_item_bor_1 {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          position: relative;

          .case_top_item_bor_img_box {
            flex: 1;
            // width: 380px;
            // height: 557px;
            max-width: calc(100% - 50px) / 3;
            max-height: 557px;
            overflow: hidden;
          }

          .case_top_item_bor_img {
            // width: 380px;
            // height: 557px;
            // max-width: calc(100% - 50px) / 3;
            // max-height: 557px;
            width: 100%;
            height: 100%;
            transition: all 0.5s;
          }

          .case_top_item_bor_img:hover {
            transform: scale(1.2);
          }

          .case_top_item_bor_btn {
            padding: 21px 0;
            color: #333333;
            font-size: 16px;

            .case_top_item_bor_btn_title {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              margin-bottom: 9px;
            }

            .case_top_item_bor_btn_txt {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              color: #999999;
              font-size: 14px;

              span {
                margin-right: 10px;
              }
            }
          }
        }

        .case_top_item_bor_1:hover {
          transition: all 0.5s;
          // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
          z-index: 100;
        }

        .case_top_item_bor_1:hover .case_top_item_bor_img {
          transform: scale(1.2);
        }
      }

      .case_top_item:nth-child(2) {
        margin: 0 25px;
      }
    }

    .case_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 20px;

      .el-button {
        width: 380px;
        height: 60px;
        background: #000000;
        padding: 0;
        margin: 0;
        overflow: hidden;
        font-size: 16px;
        color: #ffffff;
      }
    }
  }

  // 观点
  .point {
    background-color: #fff;
    color: #fff;

    .banner {
      background: url("../../assets/imgs/juxing.png") no-repeat;
      background-size: 100% 100%;
      // height: 660px;
      padding: 156px 10%;
      box-sizing: border-box;

      .title {
        font-size: 48px;
        line-height: 76px;
      }

      .text_box {
        margin-top: 92px;
        line-height: 34px;
      }
    }
  }

  // 新闻
  .new {
    min-width: 1300px;
    display: flex;
    align-content: space-around;
    padding: 0 20px;

    .new_l {
      width: 616px;
      // max-height: 559px;
      margin-right: 25px;
      padding: 30px;
      box-sizing: border-box;
      background-color: #fff;
      cursor: pointer;

      .new_img_box {
        // width: 478px;
        height: 313px;
        overflow: hidden;

        .new_img {
          // width: 478px;
          width: 100%;
          height: 313px;
          transition: all 0.5s;
        }

        .new_img:hover {
          transition: all 0.5s;
          transform: scale(1.2);
        }
      }

      .btn {
        display: flex;
        align-content: space-around;
        align-items: center;

        .btn_l {
          flex: 1;
          margin-right: 19px;

          .btn_l_title {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            color: #333333;
            font-size: 18px;
            margin: 20px 0;
          }

          .btn_l_txt {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            color: #999999;
            font-size: 14px;
            line-height: 26px;
          }
        }

        .btn_r {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #fff;
          width: 88px;
          height: 88px;
          background: #000000;
          border-radius: 10px;
          margin-top: 20px;
          font-size: 14px;

          .btn_r_title {
            font-size: 38px;
          }
        }
      }
    }

    .new_r {
      flex: 1;
      background: #fff;

      .new_r_box {
        height: 100%;
        display: flex;
        align-content: space-around;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        // overflow: hidden;

        .new_r_item {
          width: calc(100% / 2);
          // height: calc(100% / 3);
          height: calc(100% / 3);
          display: flex;
          align-content: space-around;
          align-items: center;
          justify-content: center;
          color: #333333;
          border-bottom: 1px solid #e5e5e5;
          border-right: 1px solid #e5e5e5;
          padding: 40px 30px;
          box-sizing: border-box;
          position: relative;
          right: -1px;
          bottom: -1px;
          cursor: pointer;
          transition: all 0.5s;

          .new_r_item_l {
            width: 88px;
            height: 88px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .new_r_item_l_title {
              font-size: 28px;
            }

            .new_r_item_l_time {
              font-size: 16px;
            }
          }

          .new_r_item_r {
            flex: 1;

            .new_r_item_r_title {
              font-size: 18px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              line-height: 34px;
            }

            .new_r_item_r_txt {
              font-size: 14px;
              color: #999999;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              line-height: 20px;
            }
          }
        }

        .new_r_item:nth-child(2) {
          border-right: 0;
        }

        .new_r_item:nth-child(4) {
          border-right: 0;
        }

        .new_r_item:nth-child(5) {
          border-bottom: 0;
        }

        .new_r_item:nth-child(6) {
          border-right: 0;
          border-bottom: 0;
        }

        .new_r_item:hover {
          transition: all 0.5s;
          box-shadow: 0 0 20px rgba(153, 153, 153, 1);
        }
      }
    }
  }

  .case_btns {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 48px 0;

    .el-button {
      width: 380px;
      height: 60px;
      background: #000000;
      padding: 0;
      margin: 0;
      overflow: hidden;
      font-size: 16px;
      color: #ffffff;
    }
  }

  /deep/ .el-carousel__indicators {
    text-align: left;
  }

  /deep/ .is-active .el-carousel__button {
    width: 9px;
    height: 9px;
    background: #000000 !important;
    border-radius: 50%;
  }

  /deep/ .el-carousel__indicator .el-carousel__button {
    width: 9px;
    height: 9px;
    background: #d2d2d2;
    border-radius: 50%;
  }

  /deep/.new .el-carousel__indicator--horizontal {
    padding: 0 4px;
  }
}
</style>
